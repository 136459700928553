import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-country-select',
  // templateUrl: './country-select.component.html',
  template: `
    <section>
      <div [clickBlank]="closeCountryPop.bind(this)">
        <div class="form-control nowrap" #expand_more id="buttonWidth" (click)="toggleCheckboxes($event)">
          <div id="dispSelect" class="nowrap">{{initDataLen === selectedResults.length ? 'All' : displaySelected}}</div>
          <i class="mdui-icon material-icons arr_btn">expand_more</i>
        </div>
        <div class="abso" [ngStyle]="{'width.px': expand_more.offsetWidth}">
          <div id="multiPop" class="down_box" *ngIf="countryPop">
            <div style="padding: 10px 10px 0 10px;">
              <div class="search_box">
                <label style="display: none;" [for]="inputId">Type and Search</label>
                <input [id]="inputId" class="form-control search_inp" type="text" placeholder="Type and Search"
                  [(ngModel)]="searchCode"
                  (keyup)="partSearch($event.target.value)" />
              </div>
              <div class="btn_block">
                <input class="multi_checkbox" type="checkbox" id="selectAll" name="selectAll"
                  [disabled]="selectAllBtnDis"
                  [(ngModel)]="selectAllBtn"
                  (ngModelChange)="selectAll()" />
                <label for="selectAll">
                  <span class="item-label">Select All</span>
                </label>
                <div class="reset_btn" (click)="reset()">Reset</div>
              </div>
            </div>
            <div class="optionsTab">
              <div class="checkBoxContainer" *ngFor="let option of searchResults,let i = index" style="padding:0;">
                <input class="multi_checkbox"
                  [name]="option.value"
                  [id]="option.value" type="checkbox"
                  [(checked)]="option.status"
                  (change)="selectOne([[$event,i]])" />
                <label [for]="option.value" class="resultLabel">
                  <span class="item-label">{{option.value}}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isMulShow" class="noshow"></div>
    </section>
  `,
  styleUrls: ['./country-select.component.css']
  // host: {
  //   '(window:resize)': 'onResize($event)'
  // }
})
export class CountrySelectComponent implements OnInit, OnChanges {
  public isMulShow: boolean;
  public buttonWidth: any;
  public countryPop: boolean;
  public searchResults: any = [];
  public selectedResults: any = [];
  public displaySelected: string = 'Please Select';
  public searchCode: string = '';
  public selectedWithComma: any;
  public titleSelected: any;
  public isShowModal: boolean;
  public selectAllBtn: boolean = false;
  public selectAllBtnDis: boolean = false;
  public initDataLen = 0;
  public isInitSelectedValue: boolean = false;
  public parentNeedToSelectAll: boolean = false;
  @Input()
  public inputId: string;

  public options: any;
  @Input()
  public selectionValue: any;
  @Input()
  public selectAllEvent: any;
  @Input()
  public dataSourceName: any;
  @Input()
  public initSelectedValue: any;
  @Input()
  public needToSelectAll: any;
  @Output()
  public outputData = new EventEmitter<any>();

  constructor() { }



  /**
   *
   * when the first load we get the null inputData.
   * @param {*} changes
   * @memberof CountrySelectComponent
   */
  ngOnChanges(): void {
    if (!this.inputId) {
      this.inputId = 'country_select_' + new Date().getTime();
    }
    this.parentNeedToSelectAll = this.needToSelectAll;
    if (this.selectionValue.length) {

      this.initDataLen = this.selectionValue.length;
      this.options = this.selectionValue
        .map((item) => ({ value: item.value, status: false, type: item.type }));

      this.searchResults = this.options;
      if (this.selectAllEvent) {
        this.selectAllBtn = true;
        this.selectAll();
      }else {
        if(!this.isInitSelectedValue && this.initSelectedValue && this.initSelectedValue.length>0) {
          this.isInitSelectedValue = true;
          this.selectOne(this.getInitSelectedValueParamArr());
        }else {
          this.selectAllBtn = true;
          this.selectAll();
        }
      }
      this.addStringNone();
      this.countryPop = false;
      this.countryOuter();
    }
  }

  ngOnInit() { }

  closeCountryPop(): void {
    this.countryPop = false;
  }

  getInitSelectedValueParamArr(): any[] {
    const paramArrs = [];
    this.initSelectedValue.forEach(element => {
      const index = this.selectionValue.findIndex((one) => {
        return one.value == element;
      })
      const param = {'target':{'checked':true,'id':this.selectionValue[index].value}};
      const paramArr = [param,index];
      paramArrs.push(paramArr);
    });
    return paramArrs;
  }

  /**
   *
   * toggle the dropdown component.
   * @param {*} e
   * @memberof CountrySelectComponent
   */
  toggleCheckboxes(e: any): void {
    if (this.selectionValue.length == 0) {
      this.countryPop = false;
    } else {
      this.countryPop = !this.countryPop;
      if (this.countryPop) {
        this.isShowModal = true;
      }
      this.clear();
    }
  }


  /**
   *
   * select all checkbox
   * @memberof CountrySelectComponent
   */
  selectAll(): void {
    this.displaySelected = '';
    this.selectedWithComma = '';
    this.selectedResults = [];
    if (this.selectAllBtn) {
      this.options.forEach(element => {
        element.status = true;
        this.selectedResults.push(element);
      });
      this.searchResults = this.options;
    } else {
      this.searchResults.forEach((element) => {
        element.status = false;
      });

      this.selectedResults = [];
      this.addStringNone();
    }
    this.selectedWithComma = this.transSelected(this.selectedResults);
    this.displaySelected = this.transTempArray(this.selectedWithComma);
    // this.displaySelected = 'All';
    this.titleSelected = this.transTitleSelect(this.selectedResults);
    this.countryOuter();
  }

  reset(): void {
    this.displaySelected = '';
    this.selectedWithComma = '';
    this.searchCode = '';
    this.selectedResults = [];
    this.searchResults.forEach((element) => {
      element.status = false;
    });
    this.options.forEach((element) => {
      element.status = false;
    });
    this.searchResults = this.options;
    this.selectAllBtnDis = false;
    this.selectAllBtn = false;
    this.titleSelected = this.transTitleSelect(this.selectedResults);
    this.addStringNone();
    this.countryOuter();
  }

  selectOne(paramArrs: any): void {
    //param: any, index: number
    paramArrs.forEach((param) => {
      if (param[0].target.checked) {
        this.selectedResults.push(this.searchResults[param[1]]);
      } else {
        this.selectedResults.forEach(element => {
          if (element.value == param[0].target.id) {
            this.selectedResults.splice(this.selectedResults.indexOf(element), 1);
          }
        });
      }
      this.countryArrStatus(param[0].target.id, param[0].target.checked);
    });

    this.controlAllSts();
    this.selectedWithComma = this.transSelected(this.selectedResults);
    this.displaySelected = this.transTempArray(this.selectedWithComma);
    this.titleSelected = this.transTitleSelect(this.selectedResults);

    // outer
    this.countryOuter();
  }

  clear() {
    this.searchCode = '';
    this.partSearch('');
  }

  partSearch(param: any): void {
    if (param == '') {
      this.searchResults = this.options;
      this.selectAllBtnDis = false;
    } else {
      this.searchResults = [];
      this.options.forEach(element => {
        if (element.value.toUpperCase().indexOf(param.toUpperCase()) != -1) {
          this.searchResults.push(element);
        }
      });
      this.selectAllBtnDis = true;
    }
  }

  countryArrStatus(changedItem: string, changedStatus: boolean) {
    this.options.map((element) => {
      if (element.value === changedItem) {
        element.status = changedStatus;
      }
      return element;
    });
  }

  /**
   * match each item of the search Array for the changedItem in params
   * if matched,change the status
   * @param changedItem
   * @param changedStatus
   * @returns matched items
   */
  searchArrStatus(changedItem: string, changedStatus: boolean) {
    this.searchResults.map((element) => {
      if (element.value === changedItem) {
        element.status = changedStatus;
      }
      return element;
    });
  }

  /**
   * transform Array 'selectedResults' to  temp Array 'tempArray'
   * in order to asyne with the displayed view data when mouse hover on the button
   * @param selectedResults: {value: string, status:boolean}[]
   */
  transSelected(selectedResults: { value: string, status: boolean }[]): Array<string> {
    let tempArray = [];
    for (let i = 0; i < selectedResults.length; i++) {
      if (selectedResults.length > 0) {
        if (i == selectedResults.length - 1) {
          tempArray.push(selectedResults[i].value);
        } else {
          tempArray.push(selectedResults[i].value + ', ');
        }
      } else {
        tempArray = [];
      }
    }
    return tempArray;
  }

  transTitleSelect(selectedResults: { value: string, status: boolean }[]): Array<string> {
    let tempArray = [];
    if (selectedResults.length > 0) {
      for (let i = 0; i < selectedResults.length; i++) {
        tempArray.push(' ' + selectedResults[i].value);
      }
    } else {
      tempArray = [];
    }
    return tempArray;
  }

  transTempArray(tempArray: Array<string>): string {
    let dispTemp = '';
    if (tempArray.length > 0) {
      tempArray.forEach((element) => {
        dispTemp = dispTemp + element;
      });
    } else {
      dispTemp = 'Please Select';
    }
    return dispTemp;
  }

  addStringNone() {
    if (this.selectedResults.length == 0) {
      this.displaySelected = 'Please Select';
      this.titleSelected = this.transTitleSelect([]);
    }
  }


  /**
   *
   * output method for parent view.
   * @memberof CountrySelectComponent
   */
  countryOuter(): void {
    // this.outputData.emit(this.selectedResults);
    this.outputData.emit({
      name: this.dataSourceName,
      arr: this.selectedResults || []
    });
  }

  controlAllSts() {
    let index = 0;
    this.options.forEach(element => {
      if (!element.status) return false;
      index++;
    });
    if (index == this.options.length) {
      this.selectAllBtn = true;
    } else {
      this.selectAllBtn = false;
    }
  }
}
