import { AdComponent } from './../../../../../common/tooltip/tooltip.directive';
import { environment } from 'environments/environment';
import { Component, OnInit, Output, EventEmitter, ElementRef, Renderer2, ViewChild, SimpleChanges, OnChanges, ChangeDetectorRef, AfterViewChecked, HostListener } from '@angular/core';
import { Factory } from '../../../../../app.factory';
import { MessageService } from 'app/common/services/message/messageService';
import { AuthService } from 'app/common/services/UserAuth/AuthService';
import { FilesUpload } from 'app/common/enum/warning.enum'
import { Subscription } from 'rxjs';
import { RecordType, CommenString, RecordStatus, ReviewOperation } from 'app/common/enum/type.enum';
import { DocumentStatus } from 'app/common/enum/DocumentStatus';
import { forEach } from 'lodash';


@Component({
    selector: 'Documents',
    templateUrl: './Documents.component.html',
    styleUrls: ['./Documents.component.css'],
    inputs: ['inputsData', 'fileInputer', 'recordTypeInputer', 'templatePage', 'templateDocPage', 'isEditProcess'],
})

export class DocumentsComponent implements OnInit, OnChanges, AfterViewChecked {
    subInstance: Subscription;
    documentStatus: any = DocumentStatus;
    commenString: any = CommenString;
    @Output() docOutputer: EventEmitter<any> = new EventEmitter();
    @Output() docSuccessFilesOutputer: EventEmitter<any> = new EventEmitter();
    public outputArr: any = [];
    public docOutputData: DocumentsOutputData = new DocumentsOutputData();
    @Output() docMaskStatusOutputer: EventEmitter<boolean> = new EventEmitter();

    private _service: any;
    public fileInputer: any;
    public recordTypeInputer: any;
    public successFiles: any = [];
    public sucFileNames: any = [];
    public existFiles: any = [];
    public delExistList: any = [];
    public editAddList: any = [];
    public user: any;
    public fileErrMsg: string = '';
    public fileErrMsgPopup: any = [];
    public inputsData: any;
    private restricted: any = ['aspx', 'ade', 'adp', 'app', 'bas', 'bat', 'chm',
        'class', 'cmd', 'com', 'cpl', 'crt', 'cs', 'dll', 'exe', 'fxp', 'hlp', 'hta', 'ins',
        'jar', 'isp', 'jse', 'lnk', 'mda', 'mdb', 'mde', 'mdt', 'mdw', 'mdz', 'msc', 'msi',
        'msp', 'mst', 'ops', 'pcd', 'pif', 'phtml', 'prf', 'prg', 'reg', 'scf', 'scr',
        'sct', 'shb', 'shs', 'sql', 'url', 'vb', 'vbe', 'vbs', 'wsc', 'wsf', 'wsh', 'bat','html','php','py','ts','ps'];
    public isFinishInit: boolean = false;
    public isMore: boolean = true;
    public isErrShow: boolean;
    public allSucFilesSts: boolean;
    public isShowModal: boolean;
    public isEditDocument: boolean = false;
    public isShowFileErr: boolean = true;
    public isLoad: boolean;
    public isNewValueShow: boolean = false;
    public isONValueShow: boolean = false;
    public isShowTable: boolean = false;
    public isAdmin: boolean = false;
    public errMsg: string;
    public _Uri: string;
    public recordStatus: string;
    public adminOperation: string;
    public role: string;
    public isShow_forReadOnly: boolean = true;
    public sendFilesCount: any = [];
    public templatePage: boolean;
    public templateDocPage: boolean;
    public isEditProcess: boolean;
    // -----Rename property begin
    public isShowRenamePopup: boolean;
    public oldFileName: string;
    public newFileName: string;
    public isShowNameError: boolean;
    public isRenameDisable: boolean = true;
    public NeedRenameIndex: any;
    public nameErrMsg: any;
    public isShowSpinner: boolean;
    public typeTipMsg:any="New Name should contain the same file extension of the original document to be changed.";
    // -----Rename property End
    public docCols = [
        // 'selectedSts',
        'documentName',
        'uploadedBy',
        'lastMod',
        'fileSize',
        'optsTool',
    ];

    public docTableData: any = [];

    @ViewChild("nz_upload")
    public nzUpload: ElementRef;
    @ViewChild("nz_upload_p")
    public nzUploadP: ElementRef;
    @ViewChild("nz_upload_p_p")
    public nzUploadPP: ElementRef;
    @ViewChild("tablediv")
    public tableDiv: ElementRef;

    public beforeUploadConut = 0;
    public zIndex = -9999;
    public nzUploadDivHeight = 0;

    constructor(private factory: Factory,
        private message: MessageService,
        private auth: AuthService,
        private renderer: Renderer2,
        private changeDetectorRef: ChangeDetectorRef
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        for (let propName in changes) {
            let chng = changes[propName];
            if (propName == 'fileInputer') {
                // if (!this.fileInputer.isValidation) {
                    //this.fileInputer.isValidation = true;
                // }
                if (this.fileInputer.isValidation == undefined) {
                    this.fileInputer.isValidation = true;
                }
            }
        }
    }

    ngDoCheck() {
        let selectedStsIndex = this.docCols.indexOf('selectedSts');
        // for array changed is not update child component issue(such as Array.push)
        if (this.successFiles) {
            if (!this.docTableData || this.successFiles.length !== this.docTableData.length) {
                this.docTableData = JSON.parse(JSON.stringify(this.successFiles));
                this.successFiles = JSON.parse(JSON.stringify(this.successFiles));
            }
        } else {
            this.successFiles = [];
        }
    }

    ngOnInit() {
        this._service = this.factory.getService();
        this.user = this.auth.getUserInforFn();
        let userRole = this.auth.determineUserRole();
        this.isAdmin = userRole === CommenString.AdminRole;
        this.isShow_forReadOnly = userRole !== CommenString.ReadOnlyRole;

        this.fileInputer.isValidation = true;
        //Parent component init
        if (this.fileInputer.isNewValueShow) {
            this.isNewValueShow = this.fileInputer.isNewValueShow;
        }
        if (this.fileInputer.isONValueShow) {
            this.isONValueShow = this.fileInputer.isONValueShow;
        }
        if (this.fileInputer.fileList) {
            this.successFiles = this.fileInputer.fileList;

            this.isDocTableShow();
        }
        if (this.fileInputer.Uri) {
            this._Uri = this.fileInputer.Uri;
            this.searchDoc(this._Uri);
        }
        if (this.fileInputer.recordStatus) {
            this.recordStatus = this.fileInputer.recordStatus;
        }
        if (this.fileInputer.role) {
            this.role = this.fileInputer.role;
        }
        // if (this.fileInputer.recordStatus == RecordStatus.PendingReview) {
            //this.isEditProcess = true;
        // }
        //Parent component change status
        this.message.get().subscribe((result) => {
            if (result.hasOwnProperty('fileList')) {
                this.successFiles = result.fileList;
                this.isDocTableShow();
            }
            if (result.hasOwnProperty('Uri')) {
                this._Uri = result.Uri;
                if (this.isFinishInit) {
                    this.searchDoc(this._Uri);
                }
            }
            if (result.hasOwnProperty('operation')) {
                this.adminOperation = result.operation;
                console.log("appOrRejectDocList", this.successFiles);
                this.apOrRjDeleteFile();
            }
        })

        this.subInstance = this.message.getDeleteMsg().subscribe((result) => {
            if (result.hasOwnProperty('deleteParam')) {
                this.deleteHPRM(result['deleteParam'][0], result['deleteParam'][1], result['deleteParam'][2]);
            }
        })
        this.isFinishInit = true;
    }

    ngAfterViewChecked() {
        if (!this.fileInputer.isRead && this.tableDiv && this.tableDiv.nativeElement) {
            this.nzUploadDivHeight = (<HTMLElement>this.tableDiv.nativeElement).offsetHeight + 16;
            this.changeDetectorRef.detectChanges();
        }
    }

    @ViewChild('editViewMask')
    editViewMaskDIV: ElementRef

    ngOnDestroy() {
        this.subInstance.unsubscribe();
    }

    isDocuments() {
        if (this.isMore == true) {
            this.isMore = false
        } else {
            this.isMore = true
        }
        $(".isSlideDocuments").slideToggle();
    }

    clickOpts(event: any, optsSts: boolean, index: number) {
        if (!optsSts) {
            this.isShowModal = true;
            this.successFiles.forEach(element => {
                element.optsSts = false;
            });
        }
        this.successFiles[index].optsSts = !optsSts;
    }

    hidePanel(event) {
        let domOpts = document.getElementById("optsTool");
        let domTd = document.getElementById("domTd");
        if (domOpts && domTd) {
            if (!domOpts.contains(event.target) && !domTd.contains(event.target)) {
                //点击到了id为child以外的区域
                this.successFiles.forEach(element => {
                    element.optsSts = false;
                });
                this.isShowModal = false;
            }
        }
    }

    changeBkg() {
        let tempNum = 0;
        this.successFiles.forEach(element => {
            if (!element.selectedSts) return false;
            tempNum++;
        });
        if (tempNum == this.successFiles.length) {
            this.allSucFilesSts = true;
        } else {
            this.allSucFilesSts = false;
        }
    }

    /**
     * the operation of SelectAll button
     */
    changeAll() {
        // this.allSucFilesSts = !this.allSucFilesSts;
        if (this.allSucFilesSts) {
            this.successFiles.forEach(element => {
                element.selectedSts = true;
            });
        } else {
            this.successFiles.forEach(element => {
                element.selectedSts = false;
            });
        }
    }

    searchDoc(uri: string) {
        var data = { uri: uri };
        if (this.isONValueShow || this.isNewValueShow) {
            this._service.editDocumentSearch(data).then(res => {
                var filelist = [];
                if (res!==undefined &&res!==null) {
                    res.forEach(element => {
                        var res = element;
                        var file = {
                            documentName: res.FileName,
                            uploadedBy: res.UploadedBy,
                            lastMod: res.LastUpdateOn,
                            size: res.FileSize,
                            serverPath: res.Path,
                            selectedSts: false,
                            optsSts: false,
                            Uri: res.Uri,
                            Status: res.Status
                        }
                        filelist.push(file);
                    });
                }

                this.successFiles = filelist;

                if (this.successFiles.length > 0) {
                    this.successFiles.forEach(fileDoc => {
                        if (fileDoc.Status != DocumentStatus.Default) {
                            this.isEditDocument = true;
                            return;
                        }
                    });
                }

                if (this.successFiles.length == 0) {
                    if (this.editViewMaskDIV != undefined) {
                        // this.renderer.setElementStyle(this.editViewMaskDIV.nativeElement, 'display', 'none');
                        this.renderer.setStyle(this.editViewMaskDIV.nativeElement, 'display', 'none');
                    }
                }
                this.addToOutput(this.successFiles);
                this.docOutputData.data = this.outputArr ? this.outputArr : [];
                this.docOutputData.isInitLoaded = true;
                this.docOutputer.emit(this.docOutputData);
                this.sendFilesCount = [];
                this.successFiles.forEach(fileDoc => {
                    if (fileDoc.Status != DocumentStatus.Delete) {
                        this.sendFilesCount.push(fileDoc);
                    }
                });
                this.message.sendDocToRadioErr({ FileListlength: this.sendFilesCount.length });
                //define the exist files list
                this.existFiles = filelist;
                this.isDocTableShow();
                console.log('DocSearchResult', this.successFiles);
            }, err => {
                console.log('err', err);
                this.docOutputData.data = this.outputArr ? this.outputArr : [];
                this.docOutputData.isInitLoaded = true;
                this.docOutputer.emit(this.docOutputData);
                if (this.successFiles.length == 0) {
                    if (this.editViewMaskDIV != undefined) {
                        // this.renderer.setElementStyle(this.editViewMaskDIV.nativeElement, 'display', 'none');
                        this.renderer.setStyle(this.editViewMaskDIV.nativeElement, 'display', 'none');
                    }
                }
            });
        } else {
            this._service.documentSearch(data).then(res => {
                var filelist = [];
                if (res!==undefined &&res!==null) {
                    res.forEach(element => {
                        var res = element;
                        var file = {
                            documentName: res.FileName,
                            uploadedBy: res.UploadedBy,
                            lastMod: res.LastUpdateOn,
                            size: res.FileSize,
                            serverPath: res.Path,
                            selectedSts: false,
                            optsSts: false,
                            Uri: res.Uri,
                            Status: res.Status
                        }
                        filelist.push(file);
                    });
                }
                this.successFiles = filelist;

                if (this.successFiles.length == 0) {
                    if (this.editViewMaskDIV != undefined) {
                        // this.renderer.setElementStyle(this.editViewMaskDIV.nativeElement, 'display', 'none');
                        this.renderer.setStyle(this.editViewMaskDIV.nativeElement, 'display', 'none');
                    }
                }
                this.addToOutput(this.successFiles);
                this.docOutputData.data = this.outputArr ? this.outputArr : [];
                this.docOutputData.isInitLoaded = true;
                this.docOutputer.emit(this.docOutputData);
                this.sendFilesCount = [];
                this.successFiles.forEach(fileDoc => {
                    if (fileDoc.Status != DocumentStatus.Delete) {
                        this.sendFilesCount.push(fileDoc);
                    }
                });
                this.message.sendDocToRadioErr({ FileListlength: this.sendFilesCount.length });
                //define the exist files list
                this.existFiles = filelist;
                this.isDocTableShow();
                console.log('DocSearchResult', this.successFiles);
            }, err => {
                console.log('err', err);
                this.docOutputData.data = this.outputArr ? this.outputArr : [];
                this.docOutputData.isInitLoaded = true;
                this.docOutputer.emit(this.docOutputData);
                if (this.successFiles.length == 0) {
                    if (this.editViewMaskDIV != undefined) {
                        // this.renderer.setElementStyle(this.editViewMaskDIV.nativeElement, 'display', 'none');
                        this.renderer.setStyle(this.editViewMaskDIV.nativeElement, 'display', 'none');
                    }
                }
            })
        }
    }

    isDocTableShow() {
        this.isShowTable = false;
        if (this.successFiles.length > 0) {
            if (this.isNewValueShow || (this.isONValueShow && !this.fileInputer.isRead) || (!this.isONValueShow && !this.isNewValueShow && !this.fileInputer.isRead)) {
                this.successFiles.forEach(fileDoc => {
                    if (fileDoc.Status == DocumentStatus.Default
                        || fileDoc.Status == DocumentStatus.Add
                        || fileDoc.Status == DocumentStatus.Rename
                        || !fileDoc.Status) {
                        this.isShowTable = true;
                        return;
                    }
                });
            } else {
                this.isShowTable = true;
            }
            // else if (!this.isONValueShow && !this.isNewValueShow && !this.fileInputer.isRead) {
            //     this.successFiles.forEach(fileDoc => {
            //         if (fileDoc.Status == DocumentStatus.Default
            //             || !fileDoc.Status) {
            //             this.isShowTable = true;
            //             return;
            //         }
            //     });
            // } 

        } else {
            this.isShowTable = false;
        }
    }

    /**
     * documnets upload
     * @param e 
     */
    uploadFile(e) {
        // IE issue, change will be triggered twice in IE browser
        if (!e.target || !e.target.value) {
            return;
        }
        this.message.sendPrimaryChange({ key: "DisableUpload", isBtnDisable: true });
        this.isLoad = true;

        window.sessionStorage.setItem("upload.document", "doing");
        var fileArr = e.target.files;
        let FileCount = this.successFiles.filter((m) => m.Status != DocumentStatus.Delete).length;
        // user can only upload one file for Parendt Document
        if (this.inputsData.formFields.ParentOrSupportingDocument.value == RecordType.Parent) {
            if (fileArr.length == 0) {
                // reset status
                this.message.sendPrimaryChange({ key: "DisableUpload", isBtnDisable: false });
                this.isLoad = false;
                setTimeout(() => {
                    this.docMaskStatusOutputer.emit(this.isLoad);
                }, 333);

                window.sessionStorage.removeItem("upload.document");
                return;
            }
            if (fileArr.length > 1 || FileCount > 0) {
                console.log('Record Type is Parent and select multiple files!');
                e.target.value = '';
                this.message.sendMsg({ fileErrMsg: FilesUpload.RecordTypeRestrict_selectParentandUploadMultipleFiles });
                this.message.sendPrimaryChange({ key: "DisableUpload", isBtnDisable: false });
                this.isLoad = false;
                setTimeout(() => {
                    this.docMaskStatusOutputer.emit(this.isLoad);
                }, 333);
                window.sessionStorage.removeItem("upload.document");
                return;
            }
        }
        var formData = new FormData();
        this.successFiles.forEach(element => {
            if (element.Status != DocumentStatus.Delete) {
                this.sucFileNames.push(element.documentName);
            }
        });
        var filesCount = 0;
        var fileIsError = false;
        let files = [];
        for (let i = 0; i < fileArr.length; i++) {
            if (FileCount > 0 && this.sucFileNames.includes(fileArr[i].name)) {
                console.log('This file is already uploaded!');
                fileIsError = true;
                this.message.sendMsg({ fileErrMsg: FilesUpload.SameFileRestrict });
                continue;
            } else if ((fileArr[i].size) / 1024 > 512000) {
                console.log('File size exceeded!');
                fileIsError = true;
                this.message.sendMsg({ fileErrMsg: FilesUpload.Oversize });
                continue;
            } else if (this.restricted.includes(this.getFileType(fileArr[i].name))) {
                console.log('Invalid file type selected!');
                fileIsError = true;
                this.message.sendMsg({ fileErrMsg: FilesUpload.TypeRestrict });
                continue;
            } else if(this.checkFileName(fileArr[i].name)){
                console.log('Invalid file name!');
                fileIsError = true;
                this.message.sendMsg({ fileErrMsg: FilesUpload.NameRestrict });
                continue;
            } else {
                formData.append('file' + i, fileArr[i]);
                files.push(fileArr[i]);
                filesCount = filesCount + 1;
            }
        }

        this.validateDuplicateFiles(files.map(file => file.name)).then(res => {
            if (res && res.length > 0) {

                let existFileNames = [];
                res.forEach(item => {
                    if (existFileNames.indexOf(item) === -1) {
                        existFileNames.push(item);
                    }
                });
                filesCount -= existFileNames.length;
                formData = new FormData();
                files.filter(file => {
                    return existFileNames.indexOf(file.name) === -1;
                }).forEach((file, index) => {
                    formData.append('file' + index, file);
                });

                console.log('This file is already uploaded in MMPR');
                if (!fileIsError) {
                    fileIsError = true;
                    this.message.sendMsg({ fileErrMsg: FilesUpload.SameFileInSys });
                }
            }

            if (!fileIsError) {
                this.message.sendMsg({ fileNoErrMsg: '' });
            }

            if (filesCount > 0) {
                this.docMaskStatusOutputer.emit(this.isLoad);
                this._service.fileUpload(formData).then(res => {
                    this.message.sendPrimaryChange({ key: "DisableUpload", isBtnDisable: false });
                    this.isLoad = false;
                    setTimeout(() => {
                        this.docMaskStatusOutputer.emit(this.isLoad);
                    }, 333);
                    window.sessionStorage.removeItem("upload.document");

                    // console.log('upload suc',res);
                    this.addToSuc(res, fileArr);
                    this.changeBkg();
                    this.addToOutput(this.successFiles);
                    this.docOutputData.data = this.outputArr ? this.outputArr : [];
                    this.docOutputData.isInitLoaded = false;
                    this.docOutputer.emit(this.docOutputData);
                    this.docSuccessFilesOutputer.emit(this.successFiles);
                    FileCount = this.successFiles.filter((m) => m.Status != DocumentStatus.Delete).length;
                    this.sendFilesCount = [];
                    this.successFiles.forEach(fileDoc => {
                        if (fileDoc.Status != DocumentStatus.Delete) {
                            this.sendFilesCount.push(fileDoc);
                        }
                    });
                    this.message.sendDocToRadioErr({ FileListlength: this.sendFilesCount.length });
                    e.target.value = '';
                }, err => {
                    console.log('upload err', err);
                    this.message.sendMsg(err);
                    this.isLoad = false;
                });
            } else {
                this.message.sendPrimaryChange({ key: "DisableUpload", isBtnDisable: false });
                e.target.value = '';
                this.isLoad = false;
            }
        }).catch(err => {
            console.log('upload err', err);
            this.message.sendMsg(err);
            this.isLoad = false;
        });
        this.sucFileNames = [];
    }

    validateDuplicateFiles(fileNames: string[]): Promise<any> {
        const searchParams = 'format=json&q=type:[Attachment] and container:[type:[default:"Temporary Document"] or not AgreementStatus:Deleted] and typedTitle:' + fileNames.map(fileName => {
            return '"' + fileName + '"';
        }).join(',');
        return new Promise<any>((resolve, reject) => {
            this._service.HPServiceRecord(searchParams, 'properties=typedTitle').then(res => {
                let existFileNames = [];
                existFileNames = res.Results.map(item => {
                    return item.RecordTypedTitle.Value;
                }).filter(item => {
                    return fileNames.indexOf(item) !== -1;
                });
                resolve(existFileNames);
            }).catch(err => {
                reject(err);
            });
        });
    }

    apOrRjDeleteFile() {
        let DocumentList = this.successFiles.map((m) => Object.assign({}, m))
        // let DocumentList = Object.assign([],this.successFiles);
        if (!!this.role && this.role == CommenString.AdminRole) {
            if (this.adminOperation) {
                if (this.adminOperation == ReviewOperation.Approve) {
                    this.successFiles.forEach(fileDoc => {
                        if (fileDoc.Status == DocumentStatus.Delete) {
                            //add this document to 'waiting remove from DB list'
                            this.delExistList.push(fileDoc.Uri);
                            //remove the Document from current list
                            DocumentList = this.successFiles.filter(item => item !== fileDoc);
                        }
                    });
                } else if (this.adminOperation == ReviewOperation.Reject) {
                    DocumentList.forEach(fileDoc => {
                        if (fileDoc.Status == DocumentStatus.Delete) {
                            fileDoc.Status = DocumentStatus.Default;
                        }
                    });
                }
            }
        }
        this.addToOutput(DocumentList);
        this.docOutputData.data = this.outputArr ? this.outputArr : [];
        this.docOutputData.isInitLoaded = false;
        this.docOutputer.emit(this.docOutputData);
    }

    /**
     * delete documents from server for preventing Memory overflow  
     * @param documentName 
     * @param index 
     */
    deleteFile(documentName: string, index: number) {
        // BUG 137341
        if (!this.isShowModal) {
            return;
        }
        this.isShowModal = false;
        //if user remove a document that has been saved in DB
        if (this.existFiles.length > 0 && this.successFiles[index].hasOwnProperty('Uri')) {
            //if it is active record edit issue and user is not admin
            let isNotAdmin = !this.isAdmin && !!this.role
                && this.role !== CommenString.ReadOnlyRole && this.role !== CommenString.AdminRole;
            let isActiveRecord = this.recordStatus === RecordStatus.Active;
            if (isNotAdmin && isActiveRecord) {
                this.successFiles[index].Status = DocumentStatus.Delete;
            } else {
                //add this document to 'waiting remove from DB list'
                this.delExistList.push(this.successFiles[index].Uri);
                //remove the Document from current list
                this.removeFromSuc(index, 1);
            }
            //get effective files count
            const successFileLength = this.successFiles.filter((m) => m.Status != DocumentStatus.Delete).length;
            //control ParentOrSupportingDocument? Radio button
            this.sendFilesCount = [];
            this.successFiles.forEach(fileDoc => {
                if (fileDoc.Status != DocumentStatus.Delete) {
                    this.sendFilesCount.push(fileDoc);
                }
            });
            this.message.sendDocToRadioErr({ FileListlength: this.sendFilesCount.length });
            //Control the check all box
            this.changeBkg();
            this.isDocTableShow();
            this.addToOutput(this.successFiles);
            this.docOutputData.data = this.outputArr ? this.outputArr : [];
            this.docOutputData.isInitLoaded = false;
            this.docOutputer.emit(this.docOutputData);
        } else {
            var filesInfo;
            filesInfo = {
                "FilesInfo": [{
                    FileName: documentName,
                    FilePath: this.successFiles[index].serverPath
                }]
            };
            //delete the document from server
            this._service.fileDelete(filesInfo).then(res => {
                console.log('del suc', res);
                //remove the Document from current list
                this.removeFromSuc(index, 1);
                this.isDocTableShow();
                //Control the check all box
                this.changeBkg();
                this.addToOutput(this.successFiles);
                this.docOutputData.data = this.outputArr ? this.outputArr : [];
                this.docOutputData.isInitLoaded = false;
                this.docOutputer.emit(this.docOutputData);
                //get effective files count
                const sucFileLength = this.successFiles.filter((m) => m.Status != DocumentStatus.Delete).length;
                //control ParentOrSupportingDocument? Radio button
                this.sendFilesCount = [];
                this.successFiles.forEach(fileDoc => {
                    if (fileDoc.Status != DocumentStatus.Delete) {
                        this.sendFilesCount.push(fileDoc);
                    }
                });
                this.message.sendDocToRadioErr({ FileListlength: this.sendFilesCount.length });
            }, err => {
                console.log('del err', err);
            });
        }
    }

    downloadFile(successFile) {
        this.isShowModal = false;
        successFile.optsSts = false;
        const request = new XMLHttpRequest();
        request.open("GET", environment.HPApiBase + 'Record/' + successFile.Uri + '/File/document', true);
        request.setRequestHeader("Cache-Control", "no-cache");
        
        request.responseType = 'blob';
        request.onload = (e) => {
            this.afterGetFile(e, successFile);
        }
        request.send(null); // post-data
    }

    afterGetFile(e, successFile) {
        const blob = e.target.response;
        if (navigator.msSaveBlob) { // IE10+ : (has Blob, but not a[download] or URL)
            return navigator.msSaveBlob(blob, successFile.documentName);
        }
        const mimeType = "application/octet-stream";
        // handle non-Blob()+non-URL browsers:
        if (typeof blob === "string" || blob.constructor === toString) {
            try {
                return this.saver("data:" + mimeType + ";base64," + window.btoa(blob), false, successFile);
            } catch (y) {
                return this.saver("data:" + mimeType + "," + encodeURIComponent(blob), false, successFile);
            }
        }

        if (window.URL) {
            this.saver(window.URL.createObjectURL(blob), true, successFile);
        } else {
            // Blob but not URL support:
            const reader = new FileReader();
            reader.onload = (e) => {
                this.saver(reader.result, false, successFile);
            };
            reader.readAsDataURL(blob);
        }
    }

    saver(url, winMode, successFile) {
        const defaultMime = "application/octet-stream";
        // handle non-a[download] safari as best we can:
        if (/(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(navigator.userAgent)) {
            if (/^data:/.test(url)) url = "data:" + url.replace(/^data:([\w\/\-\+]+)/, defaultMime);
            if (!window.open(url)) { // popup blocked, offer direct download:
                if (confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")) {
 location.href = url; 
}
            }
            return true;
        }

        if (!winMode && /^data:/.test(url)) { // force a mime that will download:
            url = "data:" + url.replace(/^data:([\w\/\-\+]+)/, defaultMime);
        }

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("download", successFile.documentName);
        a.href = url;
        a.click();
        setTimeout(function () {
 document.body.removeChild(a); 
}, 333);
    }

    /**
     * remove item selected from files which were uploaded successfully
     * and keep association with the status of table display
     * @param index 
     */
    removeFromSuc(index: number, deleteLength: number) {
        this.successFiles.splice(index, deleteLength);
        if (this.successFiles.length <= 0) {
            // this.isShowTable = false;
            this.allSucFilesSts = false;
        }
    }

    /**
     * add FileName and FilePath to the new outputArr
     *  for outputting
     * @param inputArr 
     */
    addToOutput(inputArr: any) {
        this.outputArr = [];
        for (let i = 0; i < inputArr.length; i++) {
            this.outputArr.push({
                FileName:inputArr[i].Status == 3 ? inputArr[i].oriFileName:inputArr[i].documentName,
                newFileName: inputArr[i].documentName,
                FilePath: inputArr[i].serverPath,
                Status: inputArr[i].Status ? inputArr[i].Status : DocumentStatus.Default
            })
        }
    }

    /**
     * fix a new array for table displaying
     * @param sucResults 
     * @param fileArr 
     */
    addToSuc(sucResults: any, fileArr: any): void {
        sucResults.forEach(result => {
            let fileNamePath = result.fileName.split('\\');
            result.fileName = fileNamePath[fileNamePath.length - 1];
        });
        var lastModDate = new Date();
        let isNotAdmin = !this.isAdmin && !!this.role
            && this.role !== CommenString.AdminRole
            && this.role !== CommenString.ReadOnlyRole;

        for (let i = 0; i < sucResults.length; i++) {
            for (let j = 0; j < fileArr.length; j++) {
                if (isNotAdmin && this.recordStatus === RecordStatus.Active) {
                    if (sucResults[i].fileName == fileArr[j].name) {
                        this.successFiles.push({
                            documentName: fileArr[j].name,
                            uploadedBy: this.user.EID,
                            //lastMod: fileArr[j].lastModifiedDate,
                            lastMod: lastModDate,
                            size: fileArr[j].size,
                            serverPath: sucResults[i].serverPath,
                            selectedSts: false,
                            optsSts: false,
                            Status: DocumentStatus.Add
                        });
                    }
                } else {
                    if (sucResults[i].fileName == fileArr[j].name) {
                        this.successFiles.push({
                            documentName: fileArr[j].name,
                            uploadedBy: this.user.EID,
                            //lastMod: fileArr[j].lastModifiedDate,
                            lastMod: lastModDate,
                            size: fileArr[j].size,
                            serverPath: sucResults[i].serverPath,
                            selectedSts: false,
                            optsSts: false
                        });
                    }
                }
            }
        }
        this.isDocTableShow();
        console.log("addDocResult", this.successFiles);
    }
/**
     * check the fileName exists filetype or not
     * @param fileName 
     */
checkFileName(fileName) {
    let rs=false;
    this.restricted.forEach(el=>{
        if(fileName.includes("."+el)) {
            rs=true;
            return rs;
        }
    });
    return rs;
    
}
    /**
     * get the type of documents
     * @param filePath 
     */
    getFileType(filePath) {
        var startIndex = filePath.lastIndexOf(".");
        if (startIndex != -1)
            return filePath.substring(startIndex + 1, filePath.length).toLowerCase();
        else return "";
    }

    deleteHPRM(isNeededHistory: boolean, fn1: any, fn2: any) {
        var params = {};
        var deleteList = [];
        for (let i = 0; i < this.delExistList.length; i++) {
            // params = {"DeleteFilesInfo": [{FileRecordUri: this.delExistList[i]}]};
            // deleteList.push({FileRecordUri: this.delExistList[i]})                         
            deleteList.push({ FileRecordUri: this.delExistList[i], IsNeededHistory: isNeededHistory })
        }
        params = {
            "DeleteFilesInfo": deleteList
        }
        this._service.fileDeleteHPRM(params).then(res => {
            console.log('del suc', res);
            fn1;
            fn2;
        }, err => {
            console.log('del err', err);
            fn1;
            fn2;
        })
    }

    isAddedFile(rowData) {
        return this.fileInputer.isRead && this.isONValueShow && !!rowData.Status && rowData.Status == this.documentStatus.Add;
    }
    isDeletedFile(rowData) {
        return this.fileInputer.isRead && this.isONValueShow && !!rowData.Status && rowData.Status == this.documentStatus.Delete;
    }
    isShowFile(rowData) {
        return !rowData.Status || rowData.Status == this.documentStatus.Rename || rowData.Status == this.documentStatus.Default || (rowData.Status == this.documentStatus.Delete && !this.isNewValueShow && this.fileInputer.isRead) || (rowData.Status == this.documentStatus.Add && ((!this.isNewValueShow && !this.isONValueShow && !this.fileInputer.isRead) || (!this.isNewValueShow && this.isONValueShow && this.fileInputer.isRead) || (!this.isNewValueShow && this.isONValueShow && !this.fileInputer.isRead) || (this.isNewValueShow && !this.isONValueShow && this.fileInputer.isRead)))
    }

    isEditProcessReturn() {
        this.isDocTableShow();
        return this.isShowTable;
    }

    dragleave(e) {
        if (this.nzUpload && this.nzUpload.nativeElement &&
            this.nzUploadP && this.nzUploadP.nativeElement &&
            this.nzUploadPP && this.nzUploadPP.nativeElement &&
            (this.nzUpload.nativeElement == e.target || this.nzUploadP.nativeElement == e.target || this.nzUploadPP.nativeElement == e.target)) {
            this.zIndex = -9999;
        }
    }
    drop(e) {
        this.zIndex = -9999;
    }
    dragoverdiv(e) {
        if (!this.fileInputer.isRead && this.tableDiv && this.tableDiv.nativeElement && (this.tableDiv.nativeElement == e.target ||
            this.tableDiv.nativeElement.contains(e.target))) {
            this.zIndex = 9999;
        }
    }
    @HostListener('document:dragenter', ['$event'])
    documentDragenter(e) {
        this.hideUpload(e);
    }
    @HostListener('document:mousemove', ['$event'])
    documentMousemove(e) {
        this.hideUpload(e);
    }
    hideUpload(e) {
        if (this.nzUploadPP && this.nzUploadPP.nativeElement &&
            this.nzUploadPP.nativeElement != e.target && !this.nzUploadPP.nativeElement.contains(e.target)) {
            this.zIndex = -9999;
        }
    }
    beforeUpload = (file: File, fileList: File[]) => {
        if (fileList.length == 1 || (fileList.length > 1 && this.beforeUploadConut == (fileList.length - 1))) {
            this.beforeUploadConut = 0;
            let e = { 'target': { 'value': '1', 'files': fileList } };
            this.uploadFile(e);
        } else {
            this.beforeUploadConut++;
        }
        return false;
    }
    // ------Rename Fuction Code Begin---------
    closeRenameBox() {
        this.isShowRenamePopup = false;
        this.newFileName = "";
        this.isShowNameError = false;
    }
    TempSaveName() {
        this.newFileName = this.newFileName.trim();
        if(this.newFileName == ""){
            this.isShowNameError = true;
            this.nameErrMsg = "The file name cannot be empty.";
            this.isShowSpinner = false;  
            this.isRenameDisable = true;
            return;
        }
        this.isShowSpinner = true;
        this.isShowNameError = false;
        let sucfilelist: any = [];
        this.successFiles.forEach(element => {
            if (element.Status != DocumentStatus.Delete) {
                sucfilelist.push(element.documentName);
            }
        });
        if (sucfilelist.includes(this.newFileName)) {
            this.isShowNameError = true;
            this.nameErrMsg = this.newFileName+" is existing. Please assign new document name.";
            this.isShowSpinner = false;
        } else {
            var filename = [];
            filename.push(this.newFileName);
            this.validateDuplicateFiles(filename).then(res => {
                if (res && res.length > 0) {
                    this.isShowNameError = true;
                    this.nameErrMsg = this.newFileName+" is existing. Please assign new document name.";
                } else {
                    if (this.getFileType(this.oldFileName) != this.getFileType(this.newFileName)) {
                        this.isShowNameError = true;
                        this.nameErrMsg = "Invalid file type selected!";
                    } else {
                        var index = this.NeedRenameIndex;
                        if (this.successFiles[index].documentName == this.oldFileName) {
                            if (this.successFiles[index].Status != DocumentStatus.Add) {
                                this.successFiles[index].Status = DocumentStatus.Rename;
                            }
                            this.successFiles[index].documentName = this.newFileName;
                            if(!this.successFiles[index].oriFileName){
                                this.successFiles[index].oriFileName = this.oldFileName;
                            }
                            var lastModDate = new Date();
                            this.successFiles[index].lastMod= lastModDate;
                            this.isShowRenamePopup = false;
                            this.addToOutput(this.successFiles);
                            this.docOutputData.data = this.outputArr ? this.outputArr : [];
                            this.docOutputData.isInitLoaded = true;
                            this.docOutputer.emit(this.docOutputData);
                        }
                        this.newFileName = "";
                    }
                }
                
                this.isShowSpinner = false;
            });
        }
        sucfilelist = [];
    }
    OpenrenameBox(successFile, i) {
        successFile.optsSts = false;
        this.NeedRenameIndex = i;
        this.oldFileName = successFile.documentName;
        this.isShowRenamePopup = true;
        this.isRenameDisable = true;
        this.isShowNameError = false;
    }
    checkFileNameValid() {
        this.isShowNameError = false;
        this.isRenameDisable = true;
        var regu = '[\\\\/:*?\"<>|]';
        var re = new RegExp(regu);
        if (this.newFileName != "") {
            if (!re.test(this.newFileName)) {
                this.isRenameDisable = false;
            } else {
                this.isShowNameError = true;
                this.nameErrMsg = "Invalid file name!";
            }
        }

        //if user only type 'space' return true

    }


    // ------Rename Fuction Code End---------
}

export class DocumentsOutputData {
    data: any[];
    isInitLoaded: boolean;
}